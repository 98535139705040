<template>
  <div class="container">
    <ErrorAlert v-if="error" :error="error" />
    <bAlert
      variant="warning"
      show
      v-if="!pending && !error && submissions.length === 0"
    >
      No batches have been assigned to you. Please ask an administrator for
      assistance.
    </bAlert>
    <WeeklyTime />
    <transition name="fade" v-if="!pending" appear>
      <b-card no-body>
        <b-tabs content-class="mt-3 ml-3" fill>
          <b-tab title="Active" active>
            <div class="row">
              <BatchCard
                v-for="submission in statusSubmissions.get('active')"
                :key="submission.id"
                :submission="submission"
                :selectedUserId="selectedUserId"
                status="active"
              /></div
          ></b-tab>
          <b-tab title="Finished">
            <div class="row">
              <BatchCard
                v-for="submission in statusSubmissions.get('finished')"
                :key="submission.id"
                :submission="submission"
                :selectedUserId="selectedUserId"
                status="finished"
              /></div
          ></b-tab>
        </b-tabs>
      </b-card>
    </transition>
    <p v-if="pending">Loading…</p>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ErrorAlert from "@/components/ErrorAlert.vue";
import BatchCard from "@/components/BatchCard.vue";
import WeeklyTime from "@/components/WeeklyTime.vue";

import api from "@/api";
import { DefaultDict } from "pycollections";

export default {
  name: "OverView",
  computed: {
    ...mapGetters({
      userId: "auth/userId",
      selectedUserId: "overrideUser/selectedUserId",
    }),
    statusSubmissions() {
      const { submissions } = this;
      const groupedSubmissions = new DefaultDict([].constructor);

      submissions.forEach((submission) => {
        const { progress, isSupervisor } = submission;
        if (progress.todo < 1 && !isSupervisor) {
          groupedSubmissions.get("finished").push(submission);
          return;
        }
        groupedSubmissions.get("active").push(submission);
      });
      return groupedSubmissions;
    },
  },
  components: {
    ErrorAlert,
    BatchCard,
    WeeklyTime,
  },
  watch: {
    selectedUserId: async function (val) {
      if (!val) return;
      try {
        const resp = await api.getOverviewForUser(val);
        this.submissions = resp.data;
      } catch (error) {
        this.error = error;
      }
    },
  },
  data() {
    return {
      submissions: [],
      pending: true,
      error: false,
    };
  },
  async mounted() {
    try {
      const resp = await api.getOverview();
      this.submissions = resp.data;
    } catch (error) {
      this.error = error;
    } finally {
      this.pending = false;
    }
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
