<template>
  <div class="row">
    <div class="col-3">
      <bButton
        v-b-toggle.timeWeekly
        variant="primary"
        class="mt-2 mb-2"
        @click="updateTimes"
        :disabled="false"
      >
        {{ true ? "Show" : "Hide" }} {{ selectedUserId ? "user" : "my" }} weekly
        Time
      </bButton>
    </div>
    <div class="col-auto">
      <bCollapse id="timeWeekly" class="mt-2 mb-2">
        <bCard>
          <p class="card-text">
            Time since Monday {{ getWeekStart(0) | formatDate }}:
            {{ currentWeekTime | formatDurationMin }}
          </p>
          <p class="card-text">
            Last week ({{ getWeekStart(1) | formatDate }} -
            {{ getWeekStart(1).add(6, "days") | formatDate }}):
            {{ previoustWeekTime | formatDurationMin }}
          </p>
        </bCard>
      </bCollapse>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";

import api from "@/api";
import { formatDate, formatDurationMin } from "@/common/time.js";

export default {
  name: "WeeklyTime",
  computed: {
    ...mapGetters({
      userId: "auth/userId",
      selectedUserId: "overrideUser/selectedUserId",
    }),
  },
  methods: {
    getWeekStart(index) {
      return moment()
        .subtract(index * 7, "days")
        .startOf("isoweek");
    },
    async updateTimes() {
      const response = await api.getWeeklyTimes(
        this.selectedUserId ? this.selectedUserId : this.userId
      );
      this.currentWeekTime =
        response.data && response.data[0] ? response.data[0].sum_time_spent : 0;
      this.previoustWeekTime =
        response.data && response.data[1] ? response.data[1].sum_time_spent : 0;
    },
  },
  filters: {
    formatDate,
    formatDurationMin,
  },
  data() {
    return {
      currentWeekTime: 0,
      previoustWeekTime: 0,
      pending: true,
      error: false,
    };
  },
};
</script>
