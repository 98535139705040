const state = {
  selectedUserId: null,
};

const getters = {
  selectedUserId: (state) => state.selectedUserId,
};
const actions = {};

const mutations = {
  updateSelectedUserId(state, selectedUserId) {
    state.selectedUserId = selectedUserId;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
