import axios from "axios";

export default {
  init() {
    const token = localStorage.getItem("token");
    if (token) {
      axios.defaults.headers.common.Authorization = "Token " + token;
    }

    axios.defaults.xsrfCookieName = "csrftoken";
    axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
    axios.defaults.withCredentials = false;
  },
  prettyError(error) {
    if (!error) return error;
    const result = {
      message: error.message,
    };
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      const { data, status } = error.response;
      result.details = { data, status };
    }
    if (error.config) {
      result.url = error.config.url;
    }
    if (error.extraContext) {
      result.extraContext = error.extraContext;
    }
    return result;
  },
  getOverview() {
    return axios.get("/api/batch-submission/overview/");
  },
  getOverviewForUser(userId) {
    return axios.get(`/api/batch-submission/overview/?user_id=${userId}`);
  },
  getBatchSubmission(batchSubId) {
    return axios.get(`/api/batch-submission/${batchSubId}/`);
  },
  getToothSegments(imgId) {
    return axios.get(`/api/tooth-segments/${imgId}/`);
  },
  getAnnotations(batchSubId, imgId, review) {
    return axios.get(`/api/annotations/${batchSubId}/${imgId}/`, {
      params: { review },
    });
  },
  combineAnnotations(batchSubId, imgId) {
    return axios.post(`/api/annotations/${batchSubId}/${imgId}/combine/`);
  },
  getImageMeta(batchSubId, imgId) {
    return axios.get(
      `/api/batch-submission/${batchSubId}/radiograph/?id=${imgId}`
    );
  },
  patch(url, payload) {
    return axios.patch(url, payload);
  },
  createToothSegment(payload) {
    return axios.post("/api/tooth-segment/", payload);
  },
  updateToothSegment(toothId, payload) {
    return axios.patch(`/api/tooth-segment/${toothId}/`, payload);
  },
  mergeToothSegments(from, to) {
    return axios.get(`/api/tooth-segment/merge/?from=${from}&to=${to}`);
  },
  deleteToothSegment(toothId) {
    return axios.delete(`/api/tooth-segment/${toothId}/`);
  },
  createAnnotation(payload) {
    return axios.post("/api/annotation/", payload);
  },
  updateAnnotation(annotationId, payload) {
    return axios.patch(`/api/annotation/${annotationId}/`, payload);
  },
  deleteAnnotation(id) {
    return axios.delete(`/api/annotation/${id}/`);
  },
  deleteToothAnnotations(batchSubId, imgId, toothName = null) {
    if (toothName) {
      return axios.get(
        `/api/batch-submission/${batchSubId}/delete-annotations/?imgId=${imgId}&toothName=${toothName}`
      );
    } else {
      return axios.get(
        `/api/batch-submission/${batchSubId}/delete-annotations/?imgId=${imgId}`
      );
    }
  },
  createAnnotationEvent(payload) {
    return axios.post("/api/annotation-event/", payload);
  },
  createComment(payload) {
    return axios.post("/api/comment/", payload);
  },
  deleteComment(commentId) {
    return axios.delete(`/api/comment/${commentId}/`);
  },
  updateComment(commentId, payload) {
    return axios.patch(`/api/comment/${commentId}/`, payload);
  },
  getUsers() {
    return axios.get("/api/user/");
  },
  lookupAnnotation(payload) {
    return axios.post("/api/lookup-annotation/", payload);
  },
  getDataImport() {
    return axios.get("/api/data-import/");
  },
  postDataImport(payload) {
    return axios.post("/api/data-import/", payload);
  },
  getTimeSpentImageReport(batchSubId, imgId) {
    return axios.get(
      `/api/batch-submission/${batchSubId}/report/?imgId=${imgId}`
    );
  },
  createAnnotationAccepted(payload) {
    return axios.post("/api/annotation-accepted/", payload);
  },
  deleteAnnotationAccepted(payload) {
    return axios.delete(
      `/api/annotation-accepted/decline/?radiograph=${payload.radiograph}&batch_submission=${payload.batch_submission}`
    );
  },
  getTimeSpentReport(userId, batchId, imgId) {
    const params = {
      batch_submission__batch: batchId,
      batch_submission__user: userId,
      radiograph: imgId || undefined,
    };
    return axios.get("/api/annotation-event-time/", { params });
  },
  getBatches() {
    return axios.get("/api/batch/");
  },
  getShareableUrlData(batchId, userId) {
    return axios.get(`/api/batch-api/${batchId}/user/${userId}`);
  },
  deleteTag(batchSubId, imgId, payload) {
    return axios.delete(
      `/api/tag/batch-submission/${batchSubId}/radiograph/${imgId}`,
      { data: payload }
    );
  },
  addTag(batchSubId, imgId, payload) {
    return axios.post(
      `/api/tag/batch-submission/${batchSubId}/radiograph/${imgId}`,
      payload
    );
  },
  getWeeklyTimes(userId) {
    return axios.get(`/api/annotation-event-time/weekly/?user_id=${userId}`);
  },
  getProgress(batchSubId) {
    return axios.get(`/api/batch-submission/${batchSubId}/progress/`);
  },
};
