import axios from "axios";

const state = {
  user: false,
};

const getters = {
  loggedIn: (state) => state.user && !state.user.invalid,
  needAuthentication: (state) => state.user && state.user.invalid,
  isAdmin: (state) => state.user && state.user.is_staff,
  username: (state) => state.user && state.user.username,
  userId: (state) => state.user && state.user.id,
};

const actions = {
  async loadUser({ commit, state }, reload = false) {
    if (!reload && state.user) return;
    try {
      const resp = await axios.get("/api/user/me/");
      commit("userLoaded", resp.data);
    } catch (error) {
      commit("userLoaded", { invalid: true, error });
    }
  },
};

const mutations = {
  userLoaded(state, user) {
    state.user = user;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
