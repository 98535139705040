<template>
  <div id="app">
    <bNavbar toggleable="md" type="dark" variant="primary" class="mb-4">
      <bNavbarBrand to="/" exact>
        <img
          src="@/assets/favicon/tooth.svg"
          width="30"
          height="30"
          class="d-inline-block align-top"
          alt
        />
        Mono
      </bNavbarBrand>
      <bNavbarToggle target="nav_collapse" />
      <bCollapse is-nav id="nav_collapse">
        <bNavbarNav v-if="loggedIn">
          <bNavItem to="/" exact>Overview</bNavItem>
          <template v-if="isAdmin">
            <bNavItem to="/new-batch" exact>New Batch</bNavItem>
            <bNavItem to="/bulk-create-users" exact>Bulk Create Users</bNavItem>
            <bNavItem to="/lookup-annotation" exact
              >Lookup Annotations</bNavItem
            >
            <bNavItem to="/data-import" exact>Data Import</bNavItem>
            <bNavItem to="/time-report" exact>Time Report</bNavItem>
          </template>
        </bNavbarNav>
        <bNavbarNav class="ml-auto">
          <bFormSelect
            v-if="userId && isAdmin && $route.name == 'overview' && userOptions"
            :options="userOptions"
            :value="userId"
            @change="onChangeUser"
            class="mb-0 mr-2"
          />
          <li class="nav-item" v-else-if="username">
            <p class="nav-link mb-0 mr-2" id="username-nav">
              User: {{ username }}
            </p>
          </li>
          <bButton class="my-2 my-sm-0" variant="light" href="/logout/">
            Logout
          </bButton>
        </bNavbarNav>
      </bCollapse>
    </bNavbar>
    <router-view v-if="loggedIn" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import api from "@/api";

export default {
  name: "App",
  computed: mapGetters("auth", [
    "needAuthentication",
    "isAdmin",
    "username",
    "loggedIn",
    "userId",
  ]),
  watch: {
    needAuthentication(val) {
      if (val) window.location.replace("/login/");
    },
    async isAdmin(val) {
      if (!val) return;
      this.userOptions = await this.getUserOptions();
    },
  },
  data() {
    return {
      userOptions: null,
    };
  },
  mounted() {
    this.$store.dispatch("auth/loadUser");
  },
  methods: {
    async getUserOptions() {
      try {
        const response = await api.getUsers();
        return response.data.map((user) => {
          return { value: user.id, text: user.username };
        });
      } catch (error) {
        // No error handling because it is too high in the component tree
      }
    },
    onChangeUser(selectedUserId) {
      this.$store.commit("overrideUser/updateSelectedUserId", selectedUserId);
    },
  },
};
</script>

<style>
#username-nav {
  color: #eee;
}

html {
  overflow-y: scroll;
}
</style>
