import moment from "moment";

export const formatDurationSec = (durationInSec) => {
  const seconds = durationInSec % 60;
  const durationInMin = (durationInSec - seconds) / 60;
  const minutes = durationInMin % 60;
  const hours = (durationInMin - minutes) / 60;

  return hours
    ? `${hours}h ${minutes}m ${seconds}s`
    : `${minutes}m ${seconds}s`;
};

export const formatDurationMin = (durationInSec) => {
  const durationInMin = Math.round(durationInSec / 60);
  const minutes = durationInMin % 60;
  const hours = (durationInMin - minutes) / 60;

  return hours ? `${hours}h ${minutes}m` : `${minutes}m`;
};

export const formatDate = (date) => {
  return moment(date).format("DD-MM-YYYY");
};

export const formatDateTime = (date) => {
  return moment(date).format("DD-MM-YYYY, HH:mm");
};
