const state = {
  viewSettings: JSON.parse(localStorage.getItem("viewSettings") || "{}"),
};

const getters = {};
const actions = {};

const mutations = {
  updateViewSettings(state, viewSettings) {
    state.viewSettings = Object.assign(state.viewSettings, viewSettings);
    localStorage.setItem("viewSettings", JSON.stringify(viewSettings));
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
