<template>
  <bAlert variant="danger" dismissible :show="show" @dismissed="show = false">
    Error{{ from }}: {{ prettyError.message }}.
    <template v-if="prettyError.details">
      <br />Please include the following details in your bug report:
      <bTextarea
        readonly
        :state="false"
        :value="JSON.stringify(prettyError, null, 2)"
        max-rows="5"
        @click.native="select"
      />
    </template>
  </bAlert>
</template>

<script>
import api from "@/api";

export default {
  name: "ErrorAlert",
  props: {
    error: Error,
  },
  data() {
    return {
      show: true,
    };
  },
  watch: {
    error: function (val) {
      this.show = !!val;
    },
  },
  computed: {
    prettyError() {
      return api.prettyError(this.error);
    },
    from() {
      const { prettyError } = this;
      if (prettyError && prettyError.url) return ` loading ${prettyError.url}`;
      return "";
    },
  },
  methods: {
    select(event) {
      event.target.select();
    },
  },
};
</script>
