<template>
  <bCard
    :title="submission.batch ? submission.batch.name : `Batch ${submission.id}`"
    style="width: 20rem"
    class="m-2"
  >
    <div class="card-text">
      <dl>
        <dt>
          <b-badge
            variant="warning"
            v-if="status === 'finished' && submission.progress.self_submitted"
            >Contribution: {{ contribution }}%
          </b-badge>
        </dt>
        <dt>Start date</dt>
        <dd>{{ submission.start_date | moment }}</dd>
        <dt v-if="submission.end_date">End date</dt>
        <dd v-if="submission.end_date">
          {{ submission.end_date | moment }}
        </dd>
      </dl>
      <p v-if="submission.progress.total">
        <span
          >Batch size:
          {{ "image" | pluralize(submission.progress.total, true) }}</span
        >
        <template v-if="progressBarData && !submission.is_supervisor">
          <div>
            <span title="total time"
              >Time spent: {{ submission.time_spent | formatDurationMin }}</span
            >
            <span title="average per image">
              (Ø
              {{ avgTimeSpent | formatDurationMin }})</span
            >
          </div>
        </template>
        <template v-if="submission.batch.max_submissions !== 1">
          <div>
            <span>Max submissions: {{ submission.batch.max_submissions }}</span>
          </div>
        </template>
        <template>
          <div class="mt-3">
            <bButton
              variant="outline-info"
              size="sm"
              @click="fetchProgressBarData(submission)"
            >
              <span v-if="!progressBarData">See Detailed progress</span
              ><span v-else><BIconArrowCounterclockwise /></span>
            </bButton>
            <div v-if="progressBarData">
              <template v-if="!submission.is_supervisor">
                <!-- annotations -->
                <OverviewProgressBar
                  title="annotations:"
                  :max="progressBarData[1].total"
                  :values="progressBarData[1].values"
                />
                <!-- review level is current user batch_submission level --->
                <OverviewProgressBar
                  v-show="submission.level > 1"
                  :title="`reviews level ${submission.level}:`"
                  :max="progressBarData[submission.level].total"
                  :values="progressBarData[submission.level].values"
                />
                <!-- self submitted --->
                <OverviewProgressBar
                  title="self submitted"
                  :max="progressBarData[submission.level].total"
                  :values="selfSubmitted"
                />
              </template>
              <template v-else>
                <OverviewProgressBar
                  v-for="(section, level) in progressBarData"
                  :key="level"
                  :title="`L${level}:`"
                  :max="section.total"
                  :values="section.values"
                />
              </template>
            </div>
            <div v-else>
              <div v-if="!submission.is_supervisor" class="mt-1">
                <OverviewProgressBar
                  key="default-progress"
                  :title="`general L${submission.level} progress:`"
                  :max="submission.progress.total"
                  :values="prepareSimpleProgress(submission.progress)[0]"
                />
                <OverviewProgressBar
                  key="default-self-progress"
                  title="self submitted:"
                  :max="submission.progress.total"
                  :values="prepareSimpleProgress(submission.progress)[1]"
                />
              </div>
            </div>
          </div>
        </template>
        <template>
          <div class="comment-count">
            <br />
            <span>
              <span> pending comments: </span>
              <span
                title="Commented images [pending] are hidden from annotators"
              >
                <BIconEyeSlashFill
                  color="blue"
                  v-if="submission.batch.hide_commented_for_annotator"
              /></span>
              <span
                title="Commented images [pending] are hidden from annotators"
              >
                <BIconEyeSlashFill
                  color="green"
                  v-if="submission.batch.hide_commented_for_reviewer"
              /></span>
            </span>
          </div>
        </template>
      </p>
    </div>
    <bButtonGroup v-if="selectedUserId === null || userId === selectedUserId">
      <bButton
        :to="`/annotate/${submission.id}/1`"
        variant="primary"
        class="mr-1"
        v-if="
          submission.batch.diagnoses_count &&
          submission.level === 1 &&
          !submission.is_supervisor &&
          status !== 'finished'
        "
      >
        annotate
      </bButton>
      <bButton
        :to="`/segment/${submission.id}/1`"
        variant="primary"
        class="mr-1"
        v-if="
          submission.batch.segmentation_enabled &&
          !submission.is_supervisor &&
          submission.progress.todo &&
          status !== 'finished'
        "
      >
        segment
      </bButton>
      <bButton
        :to="`/review/${submission.id}/1`"
        variant="success"
        class="mr-1"
        v-if="
          submission.level > 1 &&
          submission.progress.todo &&
          !submission.is_supervisor &&
          status !== 'finished'
        "
      >
        review
      </bButton>
      <bButton
        :to="`/review/${submission.id}/1`"
        variant="success"
        class="mr-1"
        v-if="submission.is_supervisor"
      >
        supervisor review
      </bButton>
      <bButton
        :to="`/review-submitted/${submission.id}/1`"
        variant="info"
        class="mr-1"
        v-if="submission.progress.self_submitted"
        v-b-tooltip.bottom="'review images you have submitted'"
      >
        submitted
      </bButton>
      <bButton
        :to="`/review-submitted/${submission.id}/1`"
        variant="info"
        class="mr-1"
        v-if="submission.is_supervisor && submission.accepted_count"
        v-b-tooltip.bottom="'review images you have accepted'"
      >
        accepted
      </bButton>
    </bButtonGroup>
  </bCard>
</template>

<script>
import { mapGetters } from "vuex";
import OverviewProgressBar from "@/components/OverviewProgressBar.vue";

import moment from "moment";
import { formatDurationMin } from "../common/time";
import api from "@/api";

const settings = {
  "Not-Resolvable": {
    variant: "danger",
    striped: "striped",
  },
  "Commented-Pending": {
    variant: "warning",
    striped: "striped",
  },
  "Commented-Pending-Submitted": {
    variant: "success",
    striped: "striped",
  },
  Submitted: {
    variant: "success",
  },
  "MaxSub-Not-Complete": {
    variant: "secondary",
  },
  Legacy: {
    variant: "danger",
  },
};

const isSubmittedSameLevel = (item, level, maxSubmissions) => {
  const submissions = item.submitted?.[level - 1];
  if (submissions && level === 1 && maxSubmissions > 1) {
    return submissions >= maxSubmissions;
  } else {
    return submissions;
  }
};

const isPartiallySubmittedSameLevel = (item, maxSubmissions) => {
  const submissions = item.submitted?.[0];
  return submissions > 0 && submissions < maxSubmissions;
};

const isSubmittedOnHigherLevel = (item, level) =>
  item.submitted.slice(level).reduce((a, b) => a + b, 0) > 0;

const isLegacy = (item, level, maxSubmissions) =>
  !isSubmittedSameLevel(item, level, maxSubmissions) &&
  isSubmittedOnHigherLevel(item, level);

export default {
  name: "BatchCard",
  computed: {
    ...mapGetters({
      userId: "auth/userId",
      selectedUserId: "overrideUser/selectedUserId",
    }),
    contribution() {
      return Math.round(
        (this.submission.progress.self_submitted * 100) /
          this.submission.progress.total
      );
    },
    avgTimeSpent() {
      const { time_spent } = this.submission;
      return Math.floor((time_spent || 0) / (this.selfSubmitted[0].value || 1));
    },
  },
  components: {
    OverviewProgressBar,
  },
  props: {
    submission: Object,
    status: String,
  },
  data() {
    return {
      progressBarData: null,
      selfSubmitted: null,
    };
  },
  filters: {
    moment: function (date) {
      return moment(date).fromNow();
    },
    formatDurationMin: formatDurationMin,
  },
  methods: {
    async fetchProgressBarData(submission) {
      const resp = await api.getProgress(submission.id);
      const maxSubmissions = submission.batch.max_submissions;

      const getValue = (key, level, response) => {
        switch (key) {
          case "Not-Resolvable":
            return response.filter((item) => item.ignored).length;
          case "Commented-Pending":
            return response.filter(
              (item) =>
                !item.ignored &&
                item.commented &&
                !isSubmittedSameLevel(item, level, maxSubmissions)
            ).length;
          case "Commented-Pending-Submitted":
            return response.filter(
              (item) =>
                !item.ignored &&
                item.commented &&
                isSubmittedSameLevel(item, level, maxSubmissions)
            ).length;
          case "Submitted":
            return response.filter(
              (item) =>
                !item.ignored &&
                !item.commented &&
                isSubmittedSameLevel(item, level, maxSubmissions)
            ).length;
          case "MaxSub-Not-Complete":
            return level === 1 && maxSubmissions > 1
              ? response.filter(
                  (item) =>
                    !item.ignored &&
                    !item.commented &&
                    isPartiallySubmittedSameLevel(item, maxSubmissions) &&
                    !isSubmittedOnHigherLevel(item, 1)
                ).length
              : 0;
          case "Legacy":
            return response.filter(
              (item) =>
                !item.ignored &&
                !item.commented &&
                isLegacy(item, level, maxSubmissions)
            ).length;
        }
      };

      const progressBar = {};
      this.selfSubmitted = [
        {
          key: "self-progress",
          value: 0,
          variant: "info",
        },
      ];
      submission.batch.levels.forEach((level) => {
        progressBar[level] = {
          total: resp.data.length,
          values: Object.entries(settings).map(([key, setup]) => ({
            key,
            value: getValue(key, level, resp.data),
            variant: setup.variant,
            striped: setup.striped,
          })),
        };
        this.selfSubmitted[0].value = resp.data.filter(
          (item) => item.self_submitted
        ).length;
      });

      this.progressBarData = progressBar;
    },
    prepareSimpleProgress(progress) {
      return [
        [
          {
            key: "simple-progress",
            value: progress.total - progress.todo,
            variant: "secondary",
            striped: false,
          },
        ],
        [
          {
            key: "self-progress",
            value: progress.self_submitted,
            variant: "info",
            striped: false,
          },
        ],
      ];
    },
  },
};
</script>
